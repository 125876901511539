import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FiEdit } from "react-icons/fi";
import { AiFillDelete } from "react-icons/ai";
import { Link } from "react-router-dom";
import {
  getCategories,
  deleteAProductCategory,
  resetState,
} from "../features/pCategory/pCategorySlice";
import CustomModal from "../components/CustomModal";

const columns = [
  {
    title: "S.No.",
    dataIndex: "key",
  },
  {
    title: "Category",
    dataIndex: "title",
    sorter: (a, b) => a.title.length - b.title.length,
  },
  // {
  //   title: "Sub Category",
  //   dataIndex: "subTitle",
  // },
  {
    title: "Action",
    dataIndex: "action",
  },
];

const CategoryList = () => {
  const [open, setOpen] = useState(false);
  const [pCatId, setpCatId] = useState("");
  const showModal = (e) => {
    setOpen(true);
    setpCatId(e);
  };

  const hideModal = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetState());
    dispatch(getCategories());
  }, []);

  const pCategorystate = useSelector((state) => state.pCategory.pCategories);

  const deleteCategory = (e) => {
    dispatch(deleteAProductCategory(e));
    setOpen(false);
    setTimeout(() => {
      dispatch(getCategories());
    }, 100);
  };

  const data1 = [];
  for (let i = 0; i < pCategorystate.length; i++) {
    data1.push({
      key: i,
      title: pCategorystate[i].title,
      // subTitle: pCategorystate[i].subTitle,
      action: (
        <>
          <Link
            to={`/admin/category/${pCategorystate[i]._id}`}
            className="fs-4 text-primary"
          >
            <FiEdit />
          </Link>
          <button
            className="ms-3 fs-3 text-danger bg-transparent border-0"
            onClick={() => showModal(pCategorystate[i]._id)}
          >
            <AiFillDelete />
          </button>
        </>
      ),
    });
  }

  return (
    <>
      <div>
        <h3 className="mb-4 title">Category List</h3>
        <div>
          <Table columns={columns} dataSource={data1} />
        </div>
        <CustomModal
          hideModal={hideModal}
          open={open}
          performAction={() => {
            deleteCategory(pCatId);
          }}
          title="Are you sure you want to delete this Product Category?"
        />
      </div>
    </>
  );
};

export default CategoryList;
