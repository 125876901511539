import React, { useEffect } from "react";
import CustomInput from "../components/CustomInput";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  createCategory,
  getAProductCategory,
  resetState,
  updateAProductCategory,
} from "../features/pCategory/pCategorySlice";

let schema = yup.object().shape({
  title: yup.string().required("Product Category is Required"),
  // subTitle: yup.string().required("Product Sub Category is Required"),
});

const AddCategory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const getPCatId = location.pathname.split("/")[3];

  const newCategory = useSelector((state) => state.pCategory);
  const {
    isSuccess,
    isError,
    isLoading,
    createdCategory,
    categoryName,
    // categorySubName,
    updatedCategory,
  } = newCategory;

  useEffect(() => {
    if (getPCatId !== undefined) {
      dispatch(getAProductCategory(getPCatId));
    } else {
      dispatch(resetState());
    }
  }, [getPCatId]);

  useEffect(() => {
    if (isSuccess && createdCategory) {
      toast.success("Category Added Successfullly!");
    }
    if (isSuccess && updatedCategory) {
      toast.success("Category Updated Successfullly!");
      navigate("/admin/list-category");
    }
    if (isError) {
      toast.error("Something Went Wrong!");
    }
  }, [isSuccess, isError, isLoading]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: categoryName || "",
      // subTitle: categorySubName || "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      if (getPCatId !== undefined) {
        const data = { id: getPCatId, pCatData: values };
        dispatch(updateAProductCategory(data));
        dispatch(resetState());
      } else {
        dispatch(createCategory(values));
        formik.resetForm();
        setTimeout(() => {
          dispatch(resetState());
        }, 1000);
      }
    },
  });

  return (
    <>
      <div>
        <h3 className="mb-4 title">
          {" "}
          {getPCatId !== undefined ? "Edit" : "Add"} Category
        </h3>
        <div>
          <form action="" onSubmit={formik.handleSubmit}>
            <CustomInput
              type="text"
              label="Enter Product Category"
              name="title"
              onCh={formik.handleChange("title")}
              onBl={formik.handleBlur("title")}
              val={formik.values.title}
            />

            <div className="error">
              {formik.touched.title && formik.errors.title}
            </div>

            {/* <CustomInput
              type="text"
              label="Enter Product Sub Category"
              name="subTitle"
              onCh={formik.handleChange("subTitle")}
              onBl={formik.handleBlur("subTitle")}
              val={formik.values.subTitle}
            />

            <div className="error">
              {formik.touched.subTitle && formik.errors.subTitle}
            </div> */}

            <button
              type="submit"
              className="btn btn-success border-0 rounded-3 my-5"
            >
              {getPCatId !== undefined ? "Edit" : "Add"} Category
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddCategory;
