import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Resetpassword from "./pages/Resetpassword";
import Forgotpassword from "./pages/Forgotpassword";
import MainLayout from "./components/MainLayout";
import Dashboard from "./pages/Dashboard";
import Enquiries from "./pages/Enquiries";
import BlogList from "./pages/BlogList";
import BlogCatList from "./pages/BlogCatList";
import Orders from "./pages/Orders";
import Customers from "./pages/Customers";
import ColorList from "./pages/ColorList";
import CategoryList from "./pages/CategoryList";
import BrandList from "./pages/BrandList";
import ProductList from "./pages/ProductList";
import AddBlog from "./pages/AddBlog";
import AddBlogCat from "./pages/AddBlogCat";
import AddColor from "./pages/AddColor";
import AddCategory from "./pages/AddCategory";
import AddBrand from "./pages/AddBrand";
import AddProduct from "./pages/AddProduct";
import { Toaster } from "react-hot-toast";
import Couponlist from "./pages/Couponlist";
import AddCoupon from "./pages/AddCoupon";
import ViewEnq from "./pages/ViewEnq";
import OrderView from "./pages/OrderView";
import { PrivateRoute } from "./routing/PrivateRoute";
import { OpenRoute } from "./routing/OpenRoutes";
import BannerImg from "./pages/BannerImg";
import AddBannerImg from "./pages/AddBannerImg";
import EditBannerImg from "./pages/EditBannerImg";
import OfferList from "./pages/OfferList";
import AddOffer from "./pages/AddOffer";
import ListSize from "./pages/ListSize";
import AddSize from "./pages/AddSize";
import AddUser from "./pages/AddUser";
import NotificationList from "./pages/NotificationList";
import AddNotification from "./pages/AddNotification";
import AddAbout from "./pages/AddAbout";
import AboutUs from "./pages/AboutUs";
import SubscribeDet from "./pages/SubscribeDet";

function App() {
  return (
    <>
      <Toaster />
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <OpenRoute>
                <Login />
              </OpenRoute>
            }
          />
          <Route path="/reset-password" element={<Resetpassword />} />
          <Route path="/forgot-password" element={<Forgotpassword />} />

          <Route
            path="/admin"
            element={
              <PrivateRoute>
                <MainLayout />
              </PrivateRoute>
            }
          >
            <Route index element={<Dashboard />} />
            <Route path="enquiries" element={<Enquiries />} />
            <Route path="subscriptions" element={<SubscribeDet />} />
            <Route path="enquiries/:id" element={<ViewEnq />} />
            <Route path="blog-list" element={<BlogList />} />
            <Route path="blog-category-list" element={<BlogCatList />} />
            <Route path="orders" element={<Orders />} />
            <Route path="order/:id" element={<OrderView />} />
            <Route path="customers" element={<Customers />} />
            <Route path="list-color" element={<ColorList />} />
            <Route path="list-offer" element={<OfferList />} />
            <Route path="list-size" element={<ListSize />} />
            <Route path="list-category" element={<CategoryList />} />
            <Route path="list-brand" element={<BrandList />} />
            <Route path="product-list" element={<ProductList />} />
            <Route path="add-blog" element={<AddBlog />} />
            <Route path="add-blog/:id" element={<AddBlog />} />
            <Route path="blog-category" element={<AddBlogCat />} />
            <Route path="blog-category/:id" element={<AddBlogCat />} />
            <Route path="color" element={<AddColor />} />
            <Route path="offer" element={<AddOffer />} />
            <Route path="color/:id" element={<AddColor />} />
            <Route path="size" element={<AddSize />} />
            <Route path="size/:id" element={<AddSize />} />
            <Route path="offer/:id" element={<AddOffer />} />
            <Route path="category" element={<AddCategory />} />
            <Route path="category/:id" element={<AddCategory />} />
            <Route path="brand" element={<AddBrand />} />
            <Route path="brand/:id" element={<AddBrand />} />
            <Route path="product" element={<AddProduct />} />
            <Route path="product/:id" element={<AddProduct />} />
            <Route path="coupon-list" element={<Couponlist />} />
            <Route path="coupon" element={<AddCoupon />} />
            <Route path="coupon/:id" element={<AddCoupon />} />
            <Route path="banner-list" element={<BannerImg />} />
            <Route path="add-banner" element={<AddBannerImg />} />
            <Route path="edit-banner/:id" element={<EditBannerImg />} />
            <Route path="add-customers" element={<AddUser />} />
            <Route path="notification-list" element={<NotificationList />} />
            <Route path="notification" element={<AddNotification />} />
            <Route path="notification/:id" element={<AddNotification />} />
            <Route path="about-us" element={<AddAbout />} />
            <Route path="about-us/:id" element={<AddAbout />} />
            <Route path="about-list" element={<AboutUs />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
