import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import customerReducer from "../features/customers/customerSlice";
import productReducer from "../features/product/productSlice";
import brandReducer from "../features/brand/brandSlice";
import pCategoryReducer from "../features/pCategory/pCategorySlice";
import bCategoryReducer from "../features/bCategory/blogCategorySlice";
import colorReducer from "../features/color/colorSlice";
import blogReducer from "../features/blogs/blogSlice";
import enquiryReducer from "../features/enquiry/enquirySlice";
import uploadReducer from "../features/upload/uploadSlice";
import couponReducer from "../features/coupon/couponSlice";
import sizeReducer from "../features/size/sizeSlice";
import offerReducer from "../features/offer/offerSlice";
import notificationReducer from "../features/notification/notificationSlice";
import aboutReducer from "../features/about/aboutSlice";
import subscriberReducer from "../features/subscribe/subsSlice";


export const store = configureStore({
  reducer: {
    auth: authReducer,
    customer: customerReducer,
    product: productReducer,
    brand: brandReducer,
    pCategory: pCategoryReducer,
    color: colorReducer,
    size: sizeReducer,
    offer: offerReducer,
    blog: blogReducer,
    bCategory: bCategoryReducer,
    enquiry: enquiryReducer,
    upload: uploadReducer,
    coupon: couponReducer,
    notification: notificationReducer,
    about: aboutReducer,
    subscriber: subscriberReducer
  },
});
