import axios from "axios";
import { base_url } from "../../utils/base_url";
import { config } from "../../utils/axiosconfig";

const addNotification = async (notificationData) => {
  const response = await axios.post(
    `${base_url}/user/create-notification`,
    notificationData,
    config
  );
  return response.data;
};

const putNotification = async (notification) => {
  const response = await axios.put(
    `${base_url}/user/update-notification/${notification.id}`,
    {
      message: notification.notificationData.message,
    },
    config
  );
  return response.data;
};

const deleteNotification = async (id) => {
  const response = await axios.delete(
    `${base_url}/user/delete-notification/${id}`,
    config
  );
  return response.data;
};

const getAllNotification = async () => {
  const response = await axios.get(`${base_url}/user/all-notification`);
  return response.data;
};

const getNotification = async (id) => {
  const response = await axios.get(
    `${base_url}/user/single-notification/${id}`,
    config
  );
  return response.data;
};

const notificationService = {
  addNotification,
  putNotification,
  deleteNotification,
  getAllNotification,
  getNotification,
};

export default notificationService;
