import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import subsService from "./subsService";

export const createdSubsThunk = createAsyncThunk(
    "subs/create", 
    async(subsData, thunkAPI) => {
        try {
            await subsService.addSubs(subsData);
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
});

export const getSubsThunk = createAsyncThunk(
    "subs/get-all",
    async (thunkAPI) => {
      try {
        return await subsService.allSubs();
      } catch (error) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  );

export const getSingleSubsThunk = createAsyncThunk("subs/get-single", async(id, thunkAPI) => {
    try {
        await subsService.singleSubs(id);
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});


export const updateSubsThunk = createAsyncThunk("subs/update-subs", async(subsData, thunkAPI) => {
    try {
        await subsService.putSubs(subsData);
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});


export const deletedSubsThunk = createAsyncThunk("subs/delete-subs", async(id, thunkAPI) => {
    try {
        await subsService.deleteSubs(id);
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
})

export const resetState = createAction("Reset_all");

const initialState = {
    subscribers: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};

export const subsSlice = createSlice({
    name: "subscribers",
    initialState: initialState,
    reducers : {},
    extraReducers : (builder) => {
        builder
        .addCase(createdSubsThunk.pending , (state) => {
            state.isLoading = true;
        })
        .addCase(createdSubsThunk.fulfilled, (state, action) => {
            state.isSuccess = true;
            state.doneSubscribe = action.payload;
        })
        .addCase(createdSubsThunk.rejected, (state, action) => {
            state.isError = true;
            state.message = action.error;
        })
        .addCase(getSubsThunk.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(getSubsThunk.fulfilled, (state, action) => {
            state.isError = false;
            state.isLoading = false;
            state.isSuccess = true;
            state.subscriber = action.payload;
        })
        .addCase(getSubsThunk.rejected,(state, action) => {
            state.message = action.error;
            state.isError = true;
        })
        .addCase(getSingleSubsThunk.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(getSingleSubsThunk.fulfilled, (state, action) => {
            state.isSuccess = true;
            state.subTitle = action.payload.title;
        })
        .addCase(getSingleSubsThunk.rejected, (state, action) => {
            state.isError = true;
            state.message = action.error;
        })
        .addCase(updateSubsThunk.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(updateSubsThunk.fulfilled, (state,action) => {
            state.isSuccess = true;
            state.updatedSubscribe = action.payload;
        })
        .addCase(updateSubsThunk.rejected, (state, action) => {
            state.isError = true;
            state.message = action.error;
        })
        .addCase(deletedSubsThunk.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(deletedSubsThunk.fulfilled, (state,action) => {
            state.isSuccess = true;
            state.deletedSubscribe = action.payload;
        })
        .addCase(deletedSubsThunk.rejected, (state, action) => {
            state.isError = true;
            state.message = action.error;
        })
        .addCase(resetState, () => initialState);
    }
})

export default subsSlice.reducer;