import axios from "axios";
import { base_url } from "../../utils/base_url";
import { config } from "../../utils/axiosconfig";

export const customerSignUpp = async (user) => {
  const response = await axios.post(`${base_url}/user/register`, user);
  return response.data;
};

const login = async (user) => {
  const response = await axios.post(`${base_url}/user/admin-login`, user);
  if (response.data) {
    localStorage.setItem("user", JSON.stringify(response.data));
  }
  return response.data;
};

const getOrders = async () => {
  const response = await axios.get(`${base_url}/user/get-all-orders`, config);
  return response.data;
};

const getOrder = async (id) => {
  const response = await axios.get(
    `${base_url}/user/get-single-order/${id}`,
    config
  );
  return response.data;
};

const getMonthlyOrders = async (data) => {
  const response = await axios.get(
    `${base_url}/user/getMonthOrderIncome`,
    data
  );
  return response.data;
};

const getYearlyStats = async (data) => {
  const response = await axios.get(
    `${base_url}/user/getYearlyTotalOrders`,
    data
  );
  return response.data;
};

const updateOrderStatus = async (data) => {
  const response = await axios.put(
    `${base_url}/user/update-order/${data.id}`,
    { status: data.status },
    config
  );
  return response.data;
};

const deleteOrder = async (id) => {
  const response = await axios.delete(
    `${base_url}/user/delete-order/${id}`,
    config
  );
  return response.data;
};

const authService = {
  customerSignUpp,
  login,
  getOrders,
  getOrder,
  getMonthlyOrders,
  getYearlyStats,
  updateOrderStatus,
  deleteOrder,
};

export default authService;
