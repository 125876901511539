import { createAsyncThunk, createSlice, createAction } from "@reduxjs/toolkit";
import offerService from "./offerService";

export const getoffers = createAsyncThunk(
  "offer/get-offers",
  async (thunkAPI) => {
    try {
      return await offerService.getOffers();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createoffer = createAsyncThunk(
  "offer/create-offer",
  async (offerData, thunkAPI) => {
    try {
      return await offerService.createOffer(offerData);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAoffer = createAsyncThunk(
  "offer/get-offer",
  async (id, thunkAPI) => {
    try {
      return await offerService.getOffer(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const updateAoffer = createAsyncThunk(
  "offer/update-offer",
  async (offer, thunkAPI) => {
    try {
      return await offerService.updateOffer(offer);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteAoffer = createAsyncThunk(
  "offer/delete-offer",
  async (id, thunkAPI) => {
    try {
      return await offerService.deleteOffer(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const resetState = createAction("Reset_all");

const initialState = {
  offers: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};

export const offerSlice = createSlice({
  name: "offers",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getoffers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getoffers.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.offers = action.payload;
      })
      .addCase(getoffers.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(createoffer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createoffer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.createdOffer = action.payload;
      })
      .addCase(createoffer.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(updateAoffer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAoffer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.updatedOffer = action.payload;
      })
      .addCase(updateAoffer.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(getAoffer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAoffer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.offerName = action.payload.title;
      })
      .addCase(getAoffer.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(deleteAoffer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteAoffer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.deletedoffer = action.payload.title;
      })
      .addCase(deleteAoffer.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(resetState, () => initialState);
  },
});

export default offerSlice.reducer;
