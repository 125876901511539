import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import notificationService from "./notificationService";

export const createNotification = createAsyncThunk(
  "notification/add-notification",
  async (notificationData, thunkAPI) => {
    try {
      return await notificationService.addNotification(notificationData);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateANotification = createAsyncThunk(
  "notification/update-notification",
  async (notificationData, thunkAPI) => {
    try {
      return await notificationService.putNotification(notificationData);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteANotification = createAsyncThunk(
  "notification/delete-notification",
  async (id, thunkAPI) => {
    try {
      return await notificationService.deleteNotification(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getNotifications = createAsyncThunk(
  "notification/getAll-notification",
  async (thunkAPI) => {
    try {
      return await notificationService.getAllNotification();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getANotification = createAsyncThunk(
  "notification/get-notification",
  async (id, thunkAPI) => {
    try {
      return await notificationService.getNotification(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const resetState = createAction("Reset_all");

const initialState = {
  notifications: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};

export const notificationSlice = createSlice({
  name: "notifications",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createNotification.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createNotification.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.createdNotification = action.payload;
      })
      .addCase(createNotification.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(updateANotification.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateANotification.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.updatedNotification = action.payload;
      })
      .addCase(updateANotification.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(deleteANotification.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteANotification.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.deletedNotification = action.payload;
      })
      .addCase(deleteANotification.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(getNotifications.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getNotifications.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.notifications = action.payload;
      })
      .addCase(getNotifications.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(getANotification.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getANotification.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.notificationMessage = action.payload.message;
      })
      .addCase(getANotification.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })

      .addCase(resetState, () => initialState);
  },
});

export default notificationSlice.reducer;
