import { Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { FiEdit } from 'react-icons/fi';
import { AiFillDelete } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getBlogCategories, deleteABlogCat ,resetState } from '../features/bCategory/blogCategorySlice';
import CustomModal from "../components/CustomModal";

const columns = [
  {
    title: 'S.No.',
    dataIndex: 'key',
  },
  {
    title: 'Title',
    dataIndex: 'title',
    sorter: (a, b) => a.title.length - b.title.length,
},
{
  title: 'Action',
  dataIndex: 'action',
},  
];


const BlogCatList = () => {

  
  const [open, setOpen] = useState(false);
  const [blogCatId, setblogCatId] = useState("");
  const showModal = (e) => {
    setOpen(true);
    setblogCatId(e);
  };

  const hideModal = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resetState());
    dispatch(getBlogCategories());
  }, [])

  const bCategorystate = useSelector((state) => state.bCategory.bCategories);

  const data1 = [];
  for (let i = 0; i < bCategorystate.length; i++) {
    data1.push({
      key: i,
      title: bCategorystate[i].title,
      action: (
        <>
        <Link  to={`/admin/blog-category/${bCategorystate[i]._id}`} className='fs-4 text-primary'>
          <FiEdit/>
        </Link>
        <button
            className="ms-3 fs-3 text-danger bg-transparent border-0"
            onClick={() => showModal(bCategorystate[i]._id)}
          >
            <AiFillDelete />
          </button>
        </>
      ),
    });
  }

  const deleteBlogCategory = (e) => {
    dispatch(deleteABlogCat(e));
    setOpen(false);
    setTimeout(() => {
      dispatch(getBlogCategories());
    }, 100);
  };

    return (
        <>
         <div>
              <h3 className='mb-4 title'>Blog Category List</h3>
              <div>
                <Table columns={columns} dataSource={data1} />
          </div>
          <CustomModal
              hideModal={hideModal}
              open={open}
              performAction={() => {
                deleteBlogCategory(blogCatId);
              }}
              title="Are you sure you want to delete this blog category?"
            />
        </div>
        </>
  )
}

export default BlogCatList