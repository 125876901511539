import { Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteABrand, getBrands, resetState } from '../features/brand/brandSlice';
import { FiEdit } from 'react-icons/fi';
import { AiFillDelete } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import CustomModal from "../components/CustomModal";


const columns = [
    {
      title: 'S.No.',
      dataIndex: 'key',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      sorter: (a, b) => a.title.length - b.title.length,
  },
  {
    title: 'Action',
    dataIndex: 'action',
},  
  ];
 

const BrandList = () => {

  const [open, setOpen] = useState(false);
  const [brandId, setbrandId] = useState("");
  const showModal = (e) => {
    setOpen(true);
    setbrandId(e);
  };
  const hideModal = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetState())
    dispatch(getBrands());
  }, [])

  const brandstate = useSelector((state) => state.brand.brands);

  const data1 = [];
  for (let i = 0; i < brandstate.length; i++) {
    data1.push({
      key: i,
      title: brandstate[i].title,
      action: (
        <>
        <Link to={`/admin/brand/${brandstate[i]._id}`} className='fs-4 text-primary'>
          <FiEdit/>
        </Link>
        <button
            className="ms-3 fs-3 text-danger bg-transparent border-0"
            onClick={() => showModal(brandstate[i]._id)}
          >
            <AiFillDelete />
          </button>
        </>
      ),
    });
  }

  const deleteBrand = (e) => {
    dispatch(deleteABrand(e));

    setOpen(false);
    setTimeout(() => {
      dispatch(getBrands());
    }, 1000);
  };

    return (
        <>
         <div>
              <h3 className='mb-4 title'>Brand List</h3>
              <div>
                <Table columns={columns} dataSource={data1} />
          </div>
            <CustomModal
              hideModal={hideModal}
              open={open}
              performAction={() => {
                deleteBrand(brandId);
              }}
              title="Are you sure you want to delete this brand?"
           />
        </div>
        </>
  )
}

export default BrandList;