import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { base_url } from "../utils/base_url";

const EditBannerImg = () => {
  const [carousel, setCarousel] = useState([]);
  const [photo, setPhoto] = useState("");
  const navigate = useNavigate();
  const params = useParams();

  const getBanner = async () => {
    try {
      const { data } = await axios.get(
        `${base_url}/carousel/get-singleCarousel/${params.id}`
      );
      setCarousel(data.singleGallery);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBanner();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const bannerData = new FormData();
      bannerData.append("photo", photo);

      const { data } = await axios.put(
        `${base_url}/carousel/update-carousel/${params.id}`,
        bannerData
      );
      if (data?.success) {
        toast.success("Banner Images Updated Successfully");
        navigate("/admin/banner-list");
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Error While Updating Banner Image");
    }
  };
  return (
    <>
      <div className="">
        <div className="row">
          <div className="col-lg-9 col-md-6 col-sm-12 mt-2">
            <h3 className="fw-bold text-center">Update Banner Images </h3>

            <div className="mb-3 mt-5">
              <label className="btn btn-outline-secondary col-md-12">
                {photo ? photo.name : "Upload Photo"}
                <input
                  type="file"
                  name="photo"
                  accept="image/*"
                  onChange={(e) => setPhoto(e.target.files[0])}
                  hidden
                />
              </label>
            </div>
            <div className="mb-3">
              {photo && (
                <div className="text-center">
                  <img
                    src={URL.createObjectURL(photo)}
                    alt="Banner Image"
                    height={"200px"}
                    className="img img-responsive"
                  />
                </div>
              )}
            </div>
            <div className="mb-3">
              <button
                onClick={handleSubmit}
                className="col-md-12 btn btn-success"
              >
                Submit Image
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditBannerImg;
