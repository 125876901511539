import axios from "axios";
import { base_url } from "../../utils/base_url";
import { config } from "../../utils/axiosconfig";

const getOffers = async () => {
  const response = await axios.get(`${base_url}/offer/get-all-offer`);
  return response.data;
};

const createOffer = async (offer) => {
  const response = await axios.post(
    `${base_url}/offer/create-offer`,
    offer,
    config
  );
  return response.data;
};

const updateOffer = async (offer) => {
  const response = await axios.put(
    `${base_url}/offer/update-offer/${offer.id}`,
    {
      title: offer.offerData.title,
    },
    config
  );
  return response.data;
};

const getOffer = async (id) => {
  const response = await axios.get(
    `${base_url}/offer/get-single-offer/${id}`,
    config
  );
  return response.data;
};

const deleteOffer = async (id) => {
  const response = await axios.delete(
    `${base_url}/offer/delete-offer/${id}`,
    config
  );
  return response.data;
};

const offerService = {
  getOffers,
  createOffer,
  updateOffer,
  getOffer,
  deleteOffer,
};

export default offerService;
