import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FiEdit } from "react-icons/fi";
import { AiFillDelete } from "react-icons/ai";
import { Link } from "react-router-dom";
import CustomModal from "../components/CustomModal";
import {
  deleteAboutThunk,
  getAboutThunk,
  resetState,
} from "../features/about/aboutSlice";

const columns = [
  {
    title: "S.No.",
    dataIndex: "key",
  },
  {
    title: "Title",
    dataIndex: "title",
  },
  {
    title: "Content",
    dataIndex: "content",
  },
  {
    title: "Action",
    dataIndex: "action",
  },
];

const AboutUs = () => {
  const [open, setOpen] = useState(false);
  const [aboutId, setAboutId] = useState("");
  const showModal = (e) => {
    setOpen(true);
    setAboutId(e);
  };
  const hideModal = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetState());
    dispatch(getAboutThunk());
  }, []);

  const aboutState = useSelector((state) => state.about.abouts);

  const data1 = [];
  for (let i = 0; i < aboutState.length; i++) {
    data1.push({
      key: i,
      title: aboutState[i]?.heading,
      content: (
        <>
          <p dangerouslySetInnerHTML={{ __html: aboutState[i]?.content }}></p>
        </>
      ),
      action: (
        <>
          <Link
            to={`/admin/about-us/${aboutState[i]._id}`}
            className="fs-4 text-primary"
          >
            <FiEdit />
          </Link>
          <button
            className="ms-3 fs-3 text-danger bg-transparent border-0"
            onClick={() => showModal(aboutState[i]?._id)}
          >
            <AiFillDelete />
          </button>
        </>
      ),
    });
  }

  const deleteAbout = (e) => {
    dispatch(deleteAboutThunk(e));

    setOpen(false);
    setTimeout(() => {
      dispatch(getAboutThunk());
    }, 1000);
  };

  return (
    <>
      <div>
        <h3 className="mb-4 title">About List</h3>
        <div>
          <Table columns={columns} dataSource={data1} />
        </div>
        <CustomModal
          hideModal={hideModal}
          open={open}
          performAction={() => {
            deleteAbout(aboutId);
          }}
          title="Are you sure you want to delete this About?"
        />
      </div>
    </>
  );
};

export default AboutUs;
