import axios from "axios";
import { base_url } from "../../utils/base_url";
import { config } from "../../utils/axiosconfig";

export const addSubs = async(subsData) => {
    const response = await axios.post(`${base_url}/subscribe/`, subsData);
    return response.data;
};

export const allSubs = async() => {
    const response = await axios.get(`${base_url}/subscribe/all`);
    return response.data;
};

export const singleSubs = async(id) => {
    const response = await axios.get(`${base_url}/subscribe/single/${id}`);
    return response.data;
};

export const putSubs = async(subs) => {
    const response = await axios.put(`${base_url}/subscribe/update/${subs.id}`, 
    {
        title : subs.subsData.title,
    },
    config);
    return response.data;
};

export const deleteSubs = async(id) => {
    const response = await axios.delete(`${base_url}/subscribe/delete/${id}`,config);
    return response.data;
};


const subsService = {
    addSubs,
    allSubs,
    singleSubs,
    putSubs,
    deleteSubs
}

export default subsService;