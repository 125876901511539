import React, { useEffect, useState } from "react";
import CustomInput from "../components/CustomInput";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  createoffer,
  getAoffer,
  resetState,
  updateAoffer,
} from "../features/offer/offerSlice";

let Schema = yup.object().shape({
  title: yup.string().required("Product Offers is Required"),
});

const AddOffer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const getOfferId = location.pathname.split("/")[3];
  const newOffer = useSelector((state) => state.offer);

  const {
    isSuccess,
    isError,
    isLoading,
    createdOffer,
    updatedOffer,
    offerName,
  } = newOffer;

  useEffect(() => {
    if (getOfferId !== undefined) {
      dispatch(getAoffer(getOfferId));
    } else {
      dispatch(resetState());
    }
  }, [getOfferId]);

  useEffect(() => {
    if (isSuccess && createdOffer) {
      toast.success("Offer Added Successfullly!");
    }
    if (isSuccess && updatedOffer) {
      toast.success("Offer Updated Successfullly!");
      navigate("/admin/list-offer");
    }
    if (isError) {
      toast.error("Something Went Wrong!");
    }
  }, [isSuccess, isError, isLoading, createdOffer]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: offerName || "",
    },
    validationSchema: Schema,
    onSubmit: (values) => {
      if (getOfferId !== undefined) {
        const data = { id: getOfferId, offerData: values };
        dispatch(updateAoffer(data));
        dispatch(resetState());
      } else {
        dispatch(createoffer(values));
        formik.resetForm();
        setTimeout(() => {
          dispatch(resetState());
        }, 300);
      }
    },
  });

  return (
    <>
      <div>
        <h3 className="mb-4 title">
          {getOfferId !== undefined ? "Edit" : "Add"} Offer
        </h3>
        <div>
          <form action="" onSubmit={formik.handleSubmit}>
            <CustomInput
              type="offer"
              label="Enter Product Offer"
              name="title"
              onCh={formik.handleChange("title")}
              onBl={formik.handleBlur("title")}
              val={formik.values.title}
              id="offer"
            />

            <div className="error">
              {formik.touched.title && formik.errors.title}
            </div>
            <button
              type="submit"
              className="btn btn-success border-0 rounded-3 my-5"
            >
              {getOfferId !== undefined ? "Edit" : "Add"} Offer
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddOffer;
