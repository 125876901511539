import React from 'react'
import CustomInput from '../components/CustomInput';

const Resetpassword = () => {
  return (
    <>
    <div className='py-5' style={{ background: "#1677FF", minHeight: "100vh" }}>
      <br />
      <br />
      <br/>
      <div className='my-5 w-25 bg-white rounded-3 mx-auto p-4'>
        <h3 className='text-center'>Reset Password</h3>
        <p className='text-center'>Please enter your new password</p>
        <form action=''>
            <CustomInput type='password' label="New Password" id="pass" />
            <CustomInput type='password' label="Confirm Password" id="Confirmpass" />
            <button className='border-0 px-3 py-2 text-light fw-bold w-100' style={{ background: "#1677FF" }} type='submit'>Reset Password</button>
        </form>
      </div>
    </div>
  </>
  )
}

export default Resetpassword;