import { createAsyncThunk, createSlice, createAction } from "@reduxjs/toolkit";
import aboutService from "./aboutService";
import { toast } from "react-hot-toast";

export const createAboutThunk = createAsyncThunk(
  "about/create-about",
  async (aboutData, thunkAPI) => {
    try {
      return await aboutService.createAbout(aboutData);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getaAboutThunk = createAsyncThunk(
  "about/getA-about",
  async (id, thunkAPI) => {
    try {
      return await aboutService.getAabout(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAboutThunk = createAsyncThunk(
  "about/get-about",
  async (thunkAPI) => {
    try {
      return await aboutService.getAbout();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateAboutThunk = createAsyncThunk(
  "about/update-about",
  async (aboutData, thunkAPI) => {
    try {
      return await aboutService.udpateAbout(aboutData);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteAboutThunk = createAsyncThunk(
  "about/delete-about",
  async (id, thunkAPI) => {
    try {
      return await aboutService.deleteAbout(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const resetState = createAction("Reset_all");

const initialState = {
  abouts: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};

export const aboutSlice = createSlice({
  name: "abouts",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createAboutThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createAboutThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.createdAbout = action.payload;

      })
      .addCase(createAboutThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(getaAboutThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getaAboutThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.aboutHeading = action.payload.heading;
        state.aboutContent = action.payload.content;
      })
      .addCase(getaAboutThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(getAboutThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAboutThunk.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.abouts = action.payload;
      })
      .addCase(getAboutThunk.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(updateAboutThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAboutThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.updatedAbout = action.payload;
      })
      .addCase(updateAboutThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(deleteAboutThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteAboutThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.deletedAbout = action.payload;
      })
      .addCase(deleteAboutThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(resetState, () => initialState);
  },
});

export default aboutSlice.reducer;
