import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CustomInput from "../components/CustomInput";
import { useFormik } from "formik";
import { toast } from "react-hot-toast";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  createAboutThunk,
  getaAboutThunk,
  resetState,
  updateAboutThunk,
} from "../features/about/aboutSlice";

let Schema = yup.object().shape({
  heading: yup.string().required("About heading is Required"),
  content: yup.string().required("About content is Required"),
});

const AddAbout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const getAboutId = location.pathname.split("/")[3];
  const aboutState = useSelector((state) => state.about);

  const {
    isSuccess,
    isError,
    isLoading,
    createdAbout,
    updatedAbout,
    aboutHeading,
    aboutContent,
  } = aboutState;
  useEffect(() => {
    if (getAboutId !== undefined) {
      dispatch(getaAboutThunk(getAboutId));
    } else {
      dispatch(resetState());
    }
  }, [getAboutId]);

  useEffect(() => {
    if (isSuccess && createdAbout) {
      toast.success("About Added Successfullly!");
    }
    if (isSuccess && updatedAbout) {
      toast.success("About Updated Successfullly!");
      navigate("/admin/about-list");
    }
    if (isError) {
      toast.error("Something Went Wrong!");
    }
  }, [isSuccess, isError, isLoading]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      heading: aboutHeading || "",
      content: aboutContent || "",
    },
    validationSchema: Schema,
    onSubmit: (values) => {
      if (getAboutId !== undefined) {
        const data = { id: getAboutId, aboutData: values };
        dispatch(updateAboutThunk(data));
        dispatch(resetState());
      } else {
        dispatch(createAboutThunk(values));
        formik.resetForm();
        setTimeout(() => {
          dispatch(resetState());
        }, 300);
      }
    },
  });

  return (
    <>
      <div>
        <h3 className="mb-4 title">
          {getAboutId !== undefined ? "Edit" : "Add"} About
        </h3>

        <div className="">
          <form action="" onSubmit={formik.handleSubmit}>
            <div className="mt-4">
              <CustomInput
                type="text"
                label="Enter About Heading"
                name="heading"
                onCh={formik.handleChange("heading")}
                onBl={formik.handleBlur("heading")}
                val={formik.values.heading}
              />
            </div>
            <div className="error">
              {formik.touched.heading && formik.errors.heading}
            </div>

            <ReactQuill
              theme="snow"
              className="mt-3"
              name="content"
              onChange={formik.handleChange("content")}
              value={formik.values.content}
            />
            <div className="error">
              {formik.touched.content && formik.errors.content}
            </div>

            <button
              type="submit"
              className="btn btn-success border-0 rounded-3 my-5"
            >
              {getAboutId !== undefined ? "Edit" : "Add"} About
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddAbout;
