import axios from "axios";
import { base_url } from "../../utils/base_url";
import { config } from "../../utils/axiosconfig";

const getProducts = async () => {
  const response = await axios.get(`${base_url}/product/all-product`);
  return response.data;
};

const createProduct = async (product) => {
  const response = await axios.post(
    `${base_url}/product/create-product`,
    product,
    config
  );
  return response.data;
};

const getProduct = async (id) => {
  const response = await axios.get(
    `${base_url}/product/single-product/${id}`,
    config
  );
  return response.data;
};

const deleteProduct = async (id) => {
  const response = await axios.delete(
    `${base_url}/product/delete-product/${id}`,
    config
  );
  return response.data;
};

const updateProduct = async (product) => {
  const response = await axios.put(
    `${base_url}/product/update-product/${product.id}`,
    {
      images: product.productData.images,
      title: product.productData.title,
      price: product.productData.price,
      shippingprice: product.productData.shippingprice,
      discount: product.productData.discount,
      offers: product.productData.offers,
      brand: product.productData.brand,
      gender: product.productData.gender,
      category: product.productData.category,
      // subCategory: product.productData.subCategory,
      tags: product.productData.tags,
      color: product.productData.color,
      size: product.productData.size,
      quantity: product.productData.quantity,
      description: product.productData.description,
    },
    config
  );
  return response.data;
};

const productService = {
  getProducts,
  createProduct,
  getProduct,
  deleteProduct,
  updateProduct,
};

export default productService;
