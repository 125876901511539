import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { FiEdit } from "react-icons/fi";
import { AiFillDelete } from "react-icons/ai";
import { Link } from "react-router-dom";
import {
  deleteOrderStatus,
  getOrders,
  resetState,
  updateOrderStatus,
} from "../features/auth/authSlice";
import CustomModal from "../components/CustomModal";

const columns = [
  {
    title: "SNo",
    dataIndex: "key",
  },
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Product",
    dataIndex: "product",
  },
  {
    title: "Amount",
    dataIndex: "amount",
  },
  {
    title: "Payment Method",
    dataIndex: "payment",
  },
  {
    title: "Date",
    dataIndex: "date",
  },
  {
    title: "Select",
    dataIndex: "select",
  },
  {
    title: "Action",
    dataIndex: "action",
  },
];

const Orders = () => {
  const [open, setOpen] = useState(false);
  const [orderId, setorderId] = useState("");
  const showModal = (e) => {
    setOpen(true);
    setorderId(e);
  };

  const hideModal = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    resetState();
    dispatch(getOrders());
  }, []);
  const orderState = useSelector((state) => state.auth.orders.orders);
  const data1 = [];
  for (let i = 0; i < orderState?.length; i++) {
    data1.push({
      key: i + 1,
      name: orderState[i]?.user?.firstname,
      product: (
        <Link to={`/admin/order/${orderState[i]?._id}`}>View Orders</Link>
      ),
      amount: orderState[i]?.totalPrice,
      payment: (
        <p className="text-success fs-5 fw-bold">
          {orderState[i]?.paymentMethod}
        </p>
      ),
      date: new Date(orderState[i]?.createdAt).toLocaleString(),
      action: (
        <>
          <button
            className="ms-3 fs-3 text-danger bg-transparent border-0"
            onClick={() => showModal(orderState[i]?._id)}
          >
            <AiFillDelete />
          </button>
        </>
      ),
      select: (
        <>
          <select
            defaultValue={orderState[i]?.orderStatus}
            className="form-control form-select"
            onChange={(e) => updateOrder(orderState[i]?._id, e.target.value)}
            name=""
            id=""
          >
            <option value="Ordered">Ordered</option>
            <option value="Shipped">Shipped</option>
            <option value="Out For Delivery">Out For Delivery</option>
            <option value="Delivered">Delivered</option>
            <option value="cancelled">cancelled</option>
          </select>
        </>
      ),
    });
  }

  const updateOrder = (a, b) => {
    dispatch(updateOrderStatus({ id: a, status: b }));
  };

  const deleteOrder = (e) => {
    dispatch(deleteOrderStatus(e));

    setOpen(false);
    setTimeout(() => {
      dispatch(getOrders());
    }, 100);
  };

  return (
    <div>
      <h3 className="mb-4 title">Orders</h3>
      <div>{<Table columns={columns} dataSource={data1} />}</div>
      <CustomModal
        hideModal={hideModal}
        open={open}
        performAction={() => {
          deleteOrder(orderId);
        }}
        title="Are you sure you want to delete this blog?"
      />
    </div>
  );
};
export default Orders;
