import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FiEdit } from "react-icons/fi";
import { AiFillDelete } from "react-icons/ai";
import { Link } from "react-router-dom";
import CustomModal from "../components/CustomModal";
import {
  deleteANotification,
  getNotifications,
  resetState,
} from "../features/notification/notificationSlice";

const columns = [
  {
    title: "SNo",
    dataIndex: "key",
  },
  {
    title: "Messgae",
    dataIndex: "message",
    sorter: (a, b) => a.message.length - b.message.length,
  },
  {
    title: "Date",
    dataIndex: "date",
  },
  {
    title: "Action",
    dataIndex: "action",
  },
];
const NotificationList = () => {
  const [open, setOpen] = useState(false);
  const [notificationId, setNotificationId] = useState("");

  const showModal = (e) => {
    setOpen(true);
    setNotificationId(e);
  };

  const hideModal = () => {
    setOpen(false);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetState());
    dispatch(getNotifications());
  }, []);

  const notificationState = useSelector(
    (state) => state?.notification?.notifications?.notifications
  );
  console.log(notificationState, "88");
  const data1 = [];
  for (let i = 0; i < notificationState?.length; i++) {
    data1.push({
      key: i + 1,
      message: (
        <>
          <div>
            <p
              dangerouslySetInnerHTML={{
                __html: notificationState[i]?.message,
              }}
            ></p>
          </div>
        </>
      ),
      date: new Date(notificationState[i]?.createdAt).toLocaleString(),
      action: (
        <>
          <Link
            to={`/admin/notification/${notificationState[i]?._id}`}
            className="ms-3 fs-3 text-primary"
          >
            <FiEdit />
          </Link>
          <button
            className="ms-3 fs-3 text-danger bg-transparent border-0"
            onClick={() => showModal(notificationState[i]._id)}
          >
            <AiFillDelete />
          </button>
        </>
      ),
    });
  }

  const deleteNotification = (e) => {
    dispatch(deleteANotification(e));
    setOpen(false);
    setTimeout(() => {
      dispatch(getNotifications());
    }, 100);
  };

  return (
    <div>
      <h3 className="mb-4 title">Notifications</h3>
      <div>
        <Table columns={columns} dataSource={data1} />
      </div>
      <CustomModal
        hideModal={hideModal}
        open={open}
        performAction={() => {
          deleteNotification(notificationId);
        }}
        title="Are you sure you want to delete this Notification?"
      />
    </div>
  );
};

export default NotificationList;
