import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FiEdit } from "react-icons/fi";
import { AiFillDelete } from "react-icons/ai";
import { Link } from "react-router-dom";
import {
  deleteAProduct,
  getProducts,
  resetState,
} from "../features/product/productSlice";
import CustomModal from "../components/CustomModal";

const columns = [
  {
    title: "SNo",
    dataIndex: "key",
  },
  {
    title: "Images",
    dataIndex: "images",
  },
  {
    title: "Title",
    dataIndex: "title",
    sorter: (a, b) => a.title.length - b.title.length,
  },
  {
    title: "Brand",
    dataIndex: "brand",
    sorter: (a, b) => a.brand.length - b.brand.length,
  },
  {
    title: "Category",
    dataIndex: "category",
    sorter: (a, b) => a.category.length - b.category.length,
  },
  {
    title: "Color",
    dataIndex: "color",
  },
  {
    title: "Tags",
    dataIndex: "tags",
  },
  {
    title: "Description",
    dataIndex: "desc",
  },
  {
    title: "Price",
    dataIndex: "price",
    sorter: (a, b) => a.price - b.price,
  },
  {
    title: "Shipping Price",
    dataIndex: "shipPrice",
  },
  {
    title: "Discount Price",
    dataIndex: "discount",
  },
  {
    title: "Action",
    dataIndex: "action",
  },
];

const ProductList = () => {
  const [open, setOpen] = useState(false);
  const [prodId, setprodId] = useState("");

  const showModal = (e) => {
    setOpen(true);
    setprodId(e);
  };

  const hideModal = () => {
    setOpen(false);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resetState());
    dispatch(getProducts());
  }, []);

  const productState = useSelector((state) => state.product.products);
  const data1 = [];
  for (let i = 0; i < productState.length; i++) {
    data1.push({
      key: i + 1,
      images: (
        <img height={100} width={100} src={productState[i]?.images[0]?.url} />
      ),
      title: productState[i].title,
      desc: (
        <>
          <div>
            <p
              dangerouslySetInnerHTML={{ __html: productState[i]?.description }}
            ></p>
          </div>
        </>
      ),
      brand: productState[i]?.brand,
      category: productState[i]?.category,
      color: (
        <span
          className="selectedProductColor"
          style={{
            backgroundColor: `${productState[i]?.color?.title}`,
          }}
        ></span>
      ),
      price: `${productState[i]?.price}`,
      tags: `${productState[i]?.tags}`,
      shipPrice: `${productState[i]?.shippingprice}`,
      discount: `${productState[i]?.discount}`,
      action: (
        <>
          <Link
            to={`/admin/product/${productState[i]?._id}`}
            className="ms-3 fs-3 text-primary"
          >
            <FiEdit />
          </Link>
          <button
            className="ms-3 fs-3 text-danger bg-transparent border-0"
            onClick={() => showModal(productState[i]._id)}
          >
            <AiFillDelete />
          </button>
        </>
      ),
    });
  }

  const deleteProduct = (e) => {
    dispatch(deleteAProduct(e));
    setOpen(false);
    setTimeout(() => {
      dispatch(getProducts());
    }, 100);
  };

  return (
    <div>
      <h3 className="mb-4 title">Products</h3>
      <div>
        <Table columns={columns} dataSource={data1} />
      </div>
      <CustomModal
        hideModal={hideModal}
        open={open}
        performAction={() => {
          deleteProduct(prodId);
        }}
        title="Are you sure you want to delete this Product?"
      />
    </div>
  );
};

export default ProductList;
