import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Table } from "antd";
import { base_url } from "../utils/base_url";

const BannerImg = () => {
  const [photo, setPhoto] = useState([]);

  const getPhoto = async () => {
    try {
      const { data } = await axios.get(`${base_url}/carousel/get-carousel`);
      console.log(data);
      setPhoto(data.photos);
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getPhoto();
  }, []);
  console.log(photo);

  const dataSource = photo.map((photo, index) => {
    return {
      key: photo.photo + index,
      images: (
        <img
          style={{ height: "100px", width: "100%", objectFit: "contain" }}
          src={`${base_url}/carousel/getsingle-carouselPhoto/${photo._id}`}
          alt=""
          srcset=""
        />
      ),
      Action: (
        <div className="">
          <Link to={`/admin/edit-banner/${photo._id}`}>
            <button className="btn btn-secondary">Update</button>
          </Link>
        </div>
      ),
    };
  });

  const columns = [
    {
      title: "Images",
      dataIndex: "images",
      key: "images",
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
    },
  ];

  return (
    <>
      <div className="">
        <div className="row">
          <div className="col-lg-9 col-md-9 col-sm-6 mt-2">
            <h3 className="fw-bold mt-4 text-center">Banner Images</h3>
            <Link to="/admin/add-banner" className="btn btn-primary mt-4 mb-4">
              Add Images
            </Link>

            <Table dataSource={dataSource} columns={columns} />
          </div>
        </div>
      </div>
    </>
  );
};

export default BannerImg;
