import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useFormik } from "formik";
import { toast } from "react-hot-toast";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  createNotification,
  getANotification,
  resetState,
  updateANotification,
} from "../features/notification/notificationSlice";

let Schema = yup.object().shape({
  message: yup.string().required("Notification Message is Required"),
});

const AddNotification = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const getNotificationId = location.pathname.split("/")[3];
  const navigate = useNavigate();

  const newNotification = useSelector((state) => state?.notification);

  const {
    isSuccess,
    isError,
    isLoading,
    createdNotification,
    notificationMessage,
    updatedNotification,
  } = newNotification;

  useEffect(() => {
    if (getNotificationId !== undefined) {
      dispatch(getANotification(getNotificationId));
    } else {
      dispatch(resetState());
    }
  }, [getNotificationId]);

  useEffect(() => {
    if (isSuccess && createdNotification) {
      toast.success("Notification Added Successfullly!");
    }
    if (isSuccess && updatedNotification) {
      toast.success("Notification Updated Successfullly!");
      navigate("/admin/notification-list");
    }
    if (isError) {
      toast.error("Something Went Wrong!");
    }
  }, [isSuccess, isError, isLoading]);

  // formik validation
  const formik = useFormik({
    initialValues: {
      message: notificationMessage || "",
    },
    validationSchema: Schema,
    onSubmit: (values) => {
      if (getNotificationId !== undefined) {
        const data = { id: getNotificationId, notificationData: values };
        dispatch(updateANotification(data));
        dispatch(resetState());
      } else {
        dispatch(createNotification(values));
        formik.resetForm();
        setTimeout(() => {
          dispatch(resetState());
        }, 300);
      }
    },
  });

  return (
    <>
      <div>
        <h3 className="mb-4 title">
          {getNotificationId !== undefined ? "Edit" : "Add"} Notification
        </h3>
        <div>
          <form
            action=""
            onSubmit={formik.handleSubmit}
            className="d-flex gap-3 flex-column"
          >
            <div className="">
              <ReactQuill
                name="message"
                theme="snow"
                placeholder="Enter Message Here"
                onChange={formik.handleChange("message")}
                value={formik.values.message}
              />

              <div className="error">
                {formik.touched.message && formik.errors.message}
              </div>
            </div>

            <button
              type="submit"
              className="btn btn-success border-0 rounded-3 my-5 w-100"
            >
              {getNotificationId !== undefined ? "Edit" : "Add"} Notification
            </button>
          </form>
        </div>
      </div>
    </>
  );
};
export default AddNotification;
