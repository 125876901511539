import React, { useEffect, useState } from "react";
import { BsArrowDownRight, BsArrowUpRight } from "react-icons/bs";
import { Column } from "@ant-design/plots";
import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getMonthlyData,
  getOrders,
  getYearlyData,
} from "../features/auth/authSlice";
const columns = [
  {
    title: "SNo",
    dataIndex: "key",
  },
  {
    title: "User Name",
    dataIndex: "name",
  },
  {
    title: "Product Image",
    dataIndex: "productImage",
  },
  {
    title: "Product Name",
    dataIndex: "productName",
  },
  {
    title: "Payment Method",
    dataIndex: "payment",
  },
  {
    title: "Product Count",
    dataIndex: "product",
  },
  {
    title: "Product Color",
    dataIndex: "color",
  },
  {
    title: "Product Size",
    dataIndex: "productSize",
  },
  {
    title: "Total Price",
    dataIndex: "price",
  },
  {
    title: "Order Address",
    dataIndex: "address",
  },
  {
    title: "Order City",
    dataIndex: "city",
  },
  {
    title: "Order State",
    dataIndex: "state",
  },
  {
    title: "Locality",
    dataIndex: "locality",
  },
  {
    title: "User Mobile",
    dataIndex: "mobile",
  },
  {
    title: "Status",
    dataIndex: "status",
  },
];

const Dashboard = () => {
  const dispatch = useDispatch();

  const monthlyDataState = useSelector((state) => state?.auth?.monthlyData);
  const yearlyDataState = useSelector((state) => state?.auth?.yearlyData);

  const orderState = useSelector((state) => state?.auth?.orders.orders);
  const [dataMonthly, setDataMonthly] = useState([]);
  const [dataMonthlySales, setDataMonthlySales] = useState([]);
  const [orderData, setOrderData] = useState([]);

  const getTokenFromLocalStorage = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;

  const config3 = {
    headers: {
      Authorization: `Bearer ${
        getTokenFromLocalStorage !== null ? getTokenFromLocalStorage.token : ""
      }`,
      Accept: "application/json",
    },
  };

  useEffect(() => {
    dispatch(getMonthlyData(config3));
    dispatch(getYearlyData(config3));
    dispatch(getOrders(config3));
  }, []);

  useEffect(() => {
    let monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    let data = [];
    let monthlyOrderCount = [];
    for (let i = 0; i < monthlyDataState?.length; i++) {
      const element = monthlyDataState[i];

      data.push({
        type: monthNames[element?._id?.month],
        income: element?.amount,
      });

      monthlyOrderCount.push({
        type: monthNames[element?._id?.month],
        sales: element?.count,
      });
    }
    setDataMonthly(data);
    setDataMonthlySales(monthlyOrderCount);

    const data1 = [];
    for (let i = 0; i < orderState?.length; i++) {
      data1.push({
        key: i,
        name:
          orderState[i]?.user?.firstname + " " + orderState[i]?.user?.lastname,
        payment: (
          <p className="text-success fs-5 fw-bold">
            {orderState[i]?.paymentMethod}
          </p>
        ),
        product: orderState[i]?.orderItems?.length,
        productName: orderState[i]?.orderItems[0]?.product?.title,
        productImage: (
          <img
            height={100}
            width={100}
            src={orderState[i]?.orderItems[0]?.product?.images[0]?.url}
          />
        ),
        productSize: orderState[i]?.orderItems[0]?.size?.title,
        color: (
          <span
            className="selectedProductColor"
            style={{
              backgroundColor: `${orderState[i]?.orderItems[0]?.color?.title}`,
            }}
          ></span>
        ),

        price: orderState[i]?.totalPrice,
        mobile: orderState[i]?.shippingInfo?.mobile,
        locality: orderState[i]?.shippingInfo?.locality,
        address: orderState[i]?.shippingInfo?.address,
        city: orderState[i]?.shippingInfo?.city,
        state: orderState[i]?.shippingInfo?.state,
        status: orderState[i]?.orderStatus,
      });
    }
    setOrderData(data1);
  }, [orderState]);

  const config = {
    data: dataMonthly,
    xField: "type",
    yField: "income",
    color: ({ type }) => {
      return "#ffd333";
    },
    label: {
      position: "middle",
      style: {
        fill: "#FFFFFF",
        opacity: 1,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      type: {
        alias: "Month",
      },
      sales: {
        alias: "Income",
      },
    },
  };

  const config2 = {
    data: dataMonthlySales,
    xField: "type",
    yField: "sales",
    color: ({ type }) => {
      return "#ffd333";
    },
    label: {
      position: "middle",
      style: {
        fill: "#FFFFFF",
        opacity: 1,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      type: {
        alias: "Month",
      },
      sales: {
        alias: "Sales",
      },
    },
  };

  return (
    <div>
      <h3 className="mb-4 title">Dashboard</h3>
      <div className="d-flex justify-content-between align-items-center gap-3">
        <div className="d-flex p-3 justify-content-between align-items-end flex-grow-1 bg-white p-3 roudned-3">
          <div>
            <p className="desc">Total Income</p>
            <h4 className="mb-0 sub-title">
              Rs. {yearlyDataState && yearlyDataState[0]?.amount}
            </h4>
          </div>
          <div className="d-flex flex-column align-items-end">
            <p className="mb-0  desc">Income in Last Year from Today</p>
          </div>
        </div>
        <div className="d-flex p-3 justify-content-between align-items-end flex-grow-1 bg-white p-3 roudned-3">
          <div>
            <p className="desc">Total Sales</p>
            <h4 className="mb-0 sub-title">
              {yearlyDataState && yearlyDataState[0]?.count}
            </h4>
          </div>
          <div className="d-flex flex-column align-items-end">
            <p className="mb-0  desc">Sales in Last Year from Today</p>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between gap-3">
        <div className="mt-4 flex-grow-1 w-50">
          <h3 className="mb-5 title">Income Statics</h3>
          <div>
            <Column {...config} />
          </div>
        </div>
        <div className="mt-4 flex-grow-1 w-50">
          <h3 className="mb-5 title">Sales Statics</h3>
          <div>
            <Column {...config2} />
          </div>
        </div>
      </div>
      <div className="mt-4">
        <h3 className="mb-5 title">Recent Orders</h3>
        <div>
          <Table columns={columns} dataSource={orderData} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
