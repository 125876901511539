import axios from 'axios';
import { base_url } from '../../utils/base_url';


const getUsers = async () => {
    const response = await axios.get(`${base_url}/user/all-users`);
    return response.data;
};

const deleteUsers = async(id) => {
    const response = await axios.delete(`${base_url}/user/delete-user/${id}`);
    return response.data;
}

const customerService = {
    getUsers,
    deleteUsers
};

export default customerService;