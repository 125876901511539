import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import customerService from './customerService';


export const getUsers = createAsyncThunk('customer/get-customers', async (thunkAPI) => {
    try {
        return await customerService.getUsers();
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});


export const deleteUsersThunk = createAsyncThunk('customer/delete-customers', async (id, thunkAPI) => {
    try {
        return await customerService.deleteUsers(id);
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

const initialState = {
    customers: [],
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: "",
};

export const customerSlice = createSlice({
    name: "users",
    initialState: initialState,
    reducers: {} ,
    extraReducers: (builder) => {
        builder.addCase(getUsers.pending, (state) => {
            state.isLoading = true;
        })
            .addCase(getUsers.fulfilled, (state, action) => {
                state.isError = false;
                state.isLoading = false;
                state.isSuccess = true;
                state.customers = action.payload;
            })
            .addCase(getUsers.rejected, (state, action) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(deleteUsersThunk.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteUsersThunk.fulfilled, (state,action) => {
                state.isSuccess = true;
                state.deletedUser = action.payload;
            })
            .addCase(deleteUsersThunk.rejected, (state, action) => {
                state.isError = true;
                state.message = action.error;
            })
    },
});

export default customerSlice.reducer;