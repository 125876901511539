import axios from 'axios';
import { base_url } from '../../utils/base_url';
import { config } from "../../utils/axiosconfig";

const getEnquiry = async () => {
    const response = await axios.get(`${base_url}/enquiry/get-all-enquiry`);
    return response.data;
};


const deleteEnquiry = async (id) => {
    const response = await axios.delete(`${base_url}/enquiry/delete-enquiry/${id}`, config);
    return response.data;
  };
  const getSingleEnquiry = async (id) => {
    const response = await axios.get(`${base_url}/enquiry/get-single-enquiry/${id}`);
    return response.data;
};
  

  const udpateEnquiry = async (enq) => {
    const response = await axios.put(
      `${base_url}/enquiry/update-enquiry/${enq.id}`,
      { status: enq.enqData },
      config
    );
    return response.data;
  };

const enquiryService = {
    getEnquiry,
    deleteEnquiry,
    getSingleEnquiry,
    udpateEnquiry
};

export default enquiryService;