import { Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FiEdit } from 'react-icons/fi';
import { AiFillDelete } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { deleteAColor, getColors, resetState } from '../features/color/colorSlice';
import CustomModal from "../components/CustomModal";

const columns = [
  {
    title: 'S.No.',
    dataIndex: 'key',
  },
  {
    title: 'Title',
    dataIndex: 'title',
    sorter: (a, b) => a.title.length - b.title.length,
},
{
  title: 'Action',
  dataIndex: 'action',
},  
];


const ColorList = () => {

  const [open, setOpen] = useState(false);
  const [colorId, setcolorId] = useState("");
  const showModal = (e) => {
    setOpen(true);
    setcolorId(e);
  };

  const hideModal = () => {
    setOpen(false);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resetState());
    dispatch(getColors());
  }, []);

  const colorstate = useSelector((state) => state.color.colors);

  const data1 = [];
  for (let i = 0; i < colorstate.length; i++) {
    data1.push({
      key: i,
      title: colorstate[i].title,
      action: (
        <>
        <Link  to={`/admin/color/${colorstate[i]._id}`} className='fs-4 text-primary'>
          <FiEdit/>
        </Link>
        <button
            className="ms-3 fs-3 text-danger bg-transparent border-0"
            onClick={() => showModal(colorstate[i]._id)}
          >
            <AiFillDelete />
          </button>
        </>
      ),
    });
  }

  const deleteColor = (e) => {
    dispatch(deleteAColor(e));

    setOpen(false);
    setTimeout(() => {
      dispatch(getColors());
    }, 100);
  };

    return (
        <>
         <div>
              <h3 className='mb-4 title'>Colors List</h3>
              <div>
                <Table columns={columns} dataSource={data1} />
          </div>
          <CustomModal
            hideModal={hideModal}
            open={open}
            performAction={() => {
              deleteColor(colorId);
            }}
        title="Are you sure you want to delete this color?"
      />
        </div>
        </>
  )
}

export default ColorList;