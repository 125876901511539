import React, { useEffect, useState } from "react";
import CustomInput from "../components/CustomInput";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useFormik } from "formik";
import { toast } from "react-hot-toast";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getBrands } from "../features/brand/brandSlice";
import { getCategories } from "../features/pCategory/pCategorySlice";
import { getColors } from "../features/color/colorSlice";
import Dropzone from "react-dropzone";
import { delImg, uploadImg } from "../features/upload/uploadSlice";
import {
  createProducts,
  getAProduct,
  updateAProduct,
  resetState,
} from "../features/product/productSlice";
import { Select } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { getsizes } from "../features/size/sizeSlice";
import { getoffers } from "../features/offer/offerSlice";

let Schema = yup.object().shape({
  title: yup.string().required("Product title is Required"),
  description: yup.string().required("Product description is Required"),
  price: yup.number().required("Price is Required"),
  brand: yup.string().required("Product brand is Required"),
  category: yup.string().required("Product category is Required"),
  // subCategory: yup.string().required("Product sub category is Required"),
  tags: yup.string().required("Product Tag is Required"),
  quantity: yup.number().required("Product quantity is Required"),
});

const AddProduct = () => {
  const dispatch = useDispatch();
  const [color, setColor] = useState([]);
  const [size, setSize] = useState([]);
  const [offers, setOffers] = useState([]);
  const [images, setImages] = useState([]);
  const location = useLocation();
  const getProdId = location.pathname.split("/")[3];
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getBrands());
    dispatch(getCategories());
    dispatch(getColors());
    dispatch(getsizes());
    dispatch(getoffers());
  }, []);

  const brandState = useSelector((state) => state?.brand?.brands);
  const categoryState = useSelector((state) => state?.pCategory?.pCategories);
  const colorState = useSelector((state) => state?.color?.colors);
  const sizeState = useSelector((state) => state?.size?.sizes);
  const offerState = useSelector((state) => state?.offer?.offers);
  const imgState = useSelector((state) => state?.upload?.images);
  const newProduct = useSelector((state) => state?.product);

  const {
    isSuccess,
    isError,
    isLoading,
    createdProduct,
    prodTitle,
    prodDesc,
    prodCategory,
    // prodSubcategory,
    prodBrand,
    prodPrice,
    prodShip,
    prodOffers,
    prodDiscount,
    prodQuantity,
    prodColor,
    prodSize,
    prodGender,
    prodTags,
    prodImages,
    updatedProduct,
  } = newProduct;

  useEffect(() => {
    if (getProdId !== undefined) {
      dispatch(getAProduct(getProdId));
      img.push(prodImages);
    } else {
      dispatch(resetState());
    }
  }, [getProdId]);

  useEffect(() => {
    dispatch(resetState());
    dispatch(getCategories());
  }, []);

  useEffect(() => {
    if (isSuccess && createdProduct) {
      toast.success("Product Added Successfullly!");
    }
    if (isSuccess && updatedProduct) {
      toast.success("Product Updated Successfullly!");
      navigate("/admin/product-list");
    }
    if (isError) {
      toast.error("Something Went Wrong!");
    }
  }, [isSuccess, isError, isLoading]);

  const coloropt = [];
  colorState.forEach((i) => {
    coloropt.push({
      label: i.title,
      value: i._id,
    });
  });

  const offeropt = [];
  offerState.forEach((i) => {
    offeropt.push({
      label: i.title,
      value: i._id,
    });
  });

  const sizeopt = [];
  sizeState.forEach((i) => {
    sizeopt.push({
      label: i.title,
      value: i._id,
    });
  });

  const img = [];
  imgState.forEach((i) => {
    img.push({
      public_id: i.public_id,
      url: i.url,
    });
  });

  useEffect(() => {
    formik.values.color = color ? color : " ";
    formik.values.size = size ? size : " ";
    formik.values.offers = offers ? offers : " ";
    formik.values.images = img;
  }, [color, images, size, offers]);

  // formik validation
  const formik = useFormik({
    initialValues: {
      title: prodTitle || "",
      price: prodPrice || "",
      shippingprice: prodShip || "",
      discount: prodDiscount || "",
      brand: prodBrand || "",
      category: prodCategory || "",
      // subCategory: prodSubcategory || "",
      offers: prodOffers || "",
      tags: prodTags || "",
      color: prodColor || "",
      quantity: prodQuantity || "",
      description: prodDesc || "",
      size: prodSize || "",
      gender: prodGender || "",
    },
    validationSchema: Schema,
    onSubmit: (values) => {
      if (getProdId !== undefined) {
        const data = { id: getProdId, productData: values };
        dispatch(updateAProduct(data));
        dispatch(resetState());
      } else {
        dispatch(createProducts(values));
        formik.resetForm();
        setTimeout(() => {
          dispatch(resetState());
        }, 300);
      }
    },
  });

  const handleColors = (e) => {
    setColor(e);
  };

  const handleSizes = (e) => {
    setSize(e);
  };

  const handleOffers = (e) => {
    setOffers(e);
  };

  return (
    <>
      <div>
        <h3 className="mb-4 title">
          {getProdId !== undefined ? "Edit" : "Add"} Product
        </h3>
        <div>
          <form
            action=""
            onSubmit={formik.handleSubmit}
            className="d-flex gap-3 flex-column"
          >
            <div className="bg-white border-1 p-5 text-center">
              <Dropzone
                onDrop={(acceptedFiles) => dispatch(uploadImg(acceptedFiles))}
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <p>
                        Drag 'n' drop some files here, or click to select files
                      </p>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
            <div className="showimages d-flex flex-wrap gap-3">
              {imgState?.map((i, j) => {
                return (
                  <div className=" position-relative" key={j}>
                    <button
                      type="button"
                      onClick={() => dispatch(delImg(i.public_id))}
                      className="btn-close position-absolute"
                      style={{ top: "10px", right: "10px" }}
                    ></button>
                    <img src={i.url} alt="" width={200} height={200} />
                  </div>
                );
              })}
            </div>

            <CustomInput
              type="text"
              label="Enter Product Title"
              name="title"
              onCh={formik.handleChange("title")}
              onBl={formik.handleBlur("title")}
              val={formik.values.title}
            />

            <div className="error">
              {formik.touched.title && formik.errors.title}
            </div>

            <CustomInput
              type="text"
              label="Enter Gender Here"
              name="gender"
              onCh={formik.handleChange("gender")}
              onBl={formik.handleBlur("gender")}
              val={formik.values.gender}
            />

            <CustomInput
              type="number"
              label="Enter Product Price"
              name="price"
              onCh={formik.handleChange("price")}
              onBl={formik.handleBlur("price")}
              val={formik.values.price}
            />

            <div className="error">
              {formik.touched.price && formik.errors.price}
            </div>

            <CustomInput
              type="number"
              label="Enter Shipping Price"
              name="shippingprice"
              onCh={formik.handleChange("shippingprice")}
              onBl={formik.handleBlur("shippingprice")}
              val={formik.values.shippingprice}
            />

            <CustomInput
              type="number"
              label="Enter Discount Price On Product"
              name="discount"
              onCh={formik.handleChange("discount")}
              onBl={formik.handleBlur("discount")}
              val={formik.values.discount}
            />

            <select
              name="brand"
              onChange={formik.handleChange("brand")}
              onBlur={formik.handleBlur("brand")}
              value={formik.values.brand}
              className="form-control py-3 mb-3"
            >
              <option value="">Select Brand</option>
              {brandState?.map((i, j) => {
                return (
                  <option key={j} value={i?.title}>
                    {i?.title}
                  </option>
                );
              })}
            </select>

            <div className="error">
              {formik.touched.brand && formik.errors.brand}
            </div>

            <select
              name="category"
              onChange={formik.handleChange("category")}
              onBlur={formik.handleBlur("category")}
              value={formik.values.category}
              className="form-control py-3 mb-3"
            >
              <option value="">Select Category</option>
              {categoryState?.map((i, j) => {
                return (
                  <option key={j} value={i?.title}>
                    {i?.title}
                  </option>
                );
              })}
            </select>

            <div className="error">
              {formik.touched.category && formik.errors.category}
            </div>

            {/* <select
              name="subCategory"
              onChange={formik.handleChange("subCategory")}
              onBlur={formik.handleBlur("subCategory")}
              val={formik.values.subCategory}
              className="form-control py-3 mb-3"
            >
              <option value="">Select Sub Category</option>
              {categoryState.map((i, j) => {
                return (
                  <option key={j} value={i.subTitle}>
                    {i.subTitle}
                  </option>
                );
              })}
            </select>

            <div className="error">
              {formik.touched.subCategory && formik.errors.subCategory}
            </div> */}

            {/* <Select
              mode="multiple"
              allowClear
              className="w-100"
              placeholder="Select Sub Category"
              defaultValue={subcategory}
              onChange={(i) => handlesubcate(i)}
              options={subcategoryopt}
            /> */}

            <CustomInput
              type="text"
              label="Enter Product Tags"
              name="tags"
              onCh={formik.handleChange("tags")}
              onBl={formik.handleBlur("tags")}
              val={formik.values.tags}
            />

            <div className="error">
              {formik.touched.tags && formik.errors.tags}
            </div>

            <Select
              mode="multiple"
              allowClear
              className="w-100"
              placeholder="Select colors"
              defaultValue={color}
              onChange={(i) => handleColors(i)}
              options={coloropt}
            />

            <Select
              mode="multiple"
              allowClear
              className="w-100"
              placeholder="Select Sizes"
              defaultValue={size}
              onChange={(i) => handleSizes(i)}
              options={sizeopt}
            />

            <Select
              mode="multiple"
              allowClear
              className="w-100"
              placeholder="Select Offers"
              defaultValue={offers}
              onChange={(i) => handleOffers(i)}
              options={offeropt}
            />

            <CustomInput
              type="number"
              label="Enter Product Quantity"
              name="quantity"
              onCh={formik.handleChange("quantity")}
              onBl={formik.handleBlur("quantity")}
              val={formik.values.quantity}
            />

            <div className="error">
              {formik.touched.quantity && formik.errors.quantity}
            </div>

            <div className="">
              <ReactQuill
                name="description"
                theme="snow"
                placeholder="Enter Description Here"
                onChange={formik.handleChange("description")}
                value={formik.values.description}
              />

              <div className="error">
                {formik.touched.description && formik.errors.description}
              </div>
            </div>

            <button
              type="submit"
              className="btn btn-success border-0 rounded-3 my-5 w-100"
            >
              {getProdId !== undefined ? "Edit" : "Add"} Product
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddProduct;
