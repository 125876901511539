import { Table } from 'antd';
import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteABlog, getBlogs, resetState } from '../features/blogs/blogSlice';
import { FiEdit } from 'react-icons/fi';
import { AiFillDelete } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import CustomModal from "../components/CustomModal";

const columns = [
  {
    title: 'S.No.',
    dataIndex: 'key',
  },
  {
    title: 'Title',
    dataIndex: 'title',
    sorter: (a, b) => a.title.length - b.title.length,
  },
  {
    title: 'Category',
    dataIndex: 'category',
    sorter: (a, b) => a.category.length - b.category.length,
},
{
  title: 'Action',
  dataIndex: 'action',
},  
  ];

const BlogList = () => {

  const [open, setOpen] = useState(false);
  const [blogId, setblogId] = useState("");
  const showModal = (e) => {
    setOpen(true);
    setblogId(e);
  };

  const hideModal = () => {
    setOpen(false);
  };
  
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resetState());
    dispatch(getBlogs());
  }, []);

  const blogstate = useSelector((state) => state.blog.blogs);

  const data1 = [];
  for (let i = 0; i < blogstate.length; i++) {
    data1.push({
      key: i,
      title: blogstate[i].title,
      category: blogstate[i].category,
      action: (
        <>
        <Link  to={`/admin/add-blog/${blogstate[i].id}`} className='fs-4 text-primary'>
          <FiEdit/>
        </Link>
        <button
            className="ms-3 fs-3 text-danger bg-transparent border-0"
            onClick={() => showModal(blogstate[i]._id)}
          >
            <AiFillDelete />
          </button>
        </>
      ),
    });
  }

  const deleteBlog = (e) => {
    dispatch(deleteABlog(e));

    setOpen(false);
    setTimeout(() => {
      dispatch(getBlogs());
    }, 100);
  };
    return (
        <>
         <div>
              <h3 className='mb-4 title'>Blogs List</h3>
              <div>
                <Table columns={columns} dataSource={data1} />
          </div>
          <CustomModal
            hideModal={hideModal}
            open={open}
            performAction={() => {
              deleteBlog(blogId);
            }}
            title="Are you sure you want to delete this blog?"
          />
        </div>
        </>
  )
}

export default BlogList;