import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FiEdit } from "react-icons/fi";
import { AiFillDelete } from "react-icons/ai";
import { Link } from "react-router-dom";
import CustomModal from "../components/CustomModal";
import {
  deleteAoffer,
  getoffers,
  resetState,
} from "../features/offer/offerSlice";

const columns = [
  {
    title: "S.No.",
    dataIndex: "key",
  },
  {
    title: "Title",
    dataIndex: "title",
    sorter: (a, b) => a.title.length - b.title.length,
  },
  {
    title: "Action",
    dataIndex: "action",
  },
];

const OfferList = () => {
  const [open, setOpen] = useState(false);
  const [offerId, setofferId] = useState("");
  const showModal = (e) => {
    setOpen(true);
    setofferId(e);
  };

  const hideModal = () => {
    setOpen(false);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resetState());
    dispatch(getoffers());
  }, []);

  const offerState = useSelector((state) => state.offer.offers);

  const data1 = [];
  for (let i = 0; i < offerState.length; i++) {
    data1.push({
      key: i,
      title: offerState[i].title,
      action: (
        <>
          <Link
            to={`/admin/offer/${offerState[i]._id}`}
            className="fs-4 text-primary"
          >
            <FiEdit />
          </Link>
          <button
            className="ms-3 fs-3 text-danger bg-transparent border-0"
            onClick={() => showModal(offerState[i]._id)}
          >
            <AiFillDelete />
          </button>
        </>
      ),
    });
  }

  const deleteOffer = (e) => {
    dispatch(deleteAoffer(e));

    setOpen(false);
    setTimeout(() => {
      dispatch(getoffers());
    }, 100);
  };

  return (
    <>
      <div>
        <h3 className="mb-4 title">Offers List</h3>
        <div>
          <Table columns={columns} dataSource={data1} />
        </div>
        <CustomModal
          hideModal={hideModal}
          open={open}
          performAction={() => {
            deleteOffer(offerId);
          }}
          title="Are you sure you want to delete this offer?"
        />
      </div>
    </>
  );
};

export default OfferList;
