import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import {
  AiOutlineBgColors,
  AiOutlineDashboard,
  AiOutlineLogout,
  AiOutlineShoppingCart,
  AiOutlineUserSwitch,
} from "react-icons/ai";
import { SiBrandfolder } from "react-icons/si";
import { FaBlog, FaClipboardList, FaProductHunt } from "react-icons/fa";
import {
  BiCategoryAlt,
  BiColorFill,
  BiLogoBlogger,
  BiSolidOffer,
} from "react-icons/bi";
import { ImBlog } from "react-icons/im";
import { IoMdNotifications } from "react-icons/io";
import {
  MdCategory,
  MdLocalOffer,
  MdNotificationAdd,
  MdNotificationsActive,
  MdNotificationsPaused,
  MdOutlinePhotoSizeSelectSmall,
} from "react-icons/md";
import { Layout, Menu, Button, theme } from "antd";
import { Outlet } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { RiCouponLine } from "react-icons/ri";
import { BiCarousel } from "react-icons/bi";
import { GiCarousel } from "react-icons/gi";
import { CgCarousel } from "react-icons/cg";
import { RxFontSize } from "react-icons/rx";
import { TbCarouselVertical } from "react-icons/tb";
import { PiUsersThreeDuotone } from "react-icons/pi";
import { RiUserShared2Line } from "react-icons/ri";
import { IoNotificationsOffSharp } from "react-icons/io";
import { useSelector } from "react-redux";

const { Header, Sider, Content } = Layout;

const MainLayout = () => {
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const navigate = useNavigate();

  const authState = useSelector((state) => state.auth.user);

  return (
    <>
      <Layout>
        <Sider trigger={null} collapsible collapsed={collapsed}>
          <div className="logo">
            <h2 className="text-light fs-5 text-center py-2 mb-0">
              <span className="sm-logo">Admin Panel</span>
              <span className="lg-logo">Neelesh Futurisitic</span>
            </h2>
          </div>
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={[""]}
            onClick={({ key }) => {
              if (key === "signout") {
                localStorage.clear();
                window.location.reload();
                navigate("/");
              } else {
                navigate(key);
              }
            }}
            items={[
              {
                key: "",
                icon: <AiOutlineDashboard className="fs-4" />,
                label: "Dashboard",
              },
              {
                key: "user",
                icon: <RiUserShared2Line className="fs-4" />,
                label: "Users",
                children: [
                  {
                    key: "add-customers",
                    icon: <PiUsersThreeDuotone className="fs-4" />,
                    label: "Add Users",
                  },
                  {
                    key: "customers",
                    icon: <AiOutlineUserSwitch className="fs-4" />,
                    label: "Customers",
                  },
                ],
              },
              {
                key: "catalog",
                icon: <AiOutlineShoppingCart className="fs-4" />,
                label: "Catelog",
                children: [
                  {
                    key: "product",
                    icon: <AiOutlineShoppingCart className="fs-4" />,
                    label: "Add Product",
                  },
                  {
                    key: "product-list",
                    icon: <FaProductHunt className="fs-4" />,
                    label: "Product List",
                  },
                  {
                    key: "brand",
                    icon: <SiBrandfolder className="fs-4" />,
                    label: "Brand",
                  },
                  {
                    key: "list-brand",
                    icon: <SiBrandfolder className="fs-4" />,
                    label: "Brand List",
                  },
                  {
                    key: "category",
                    icon: <BiCategoryAlt className="fs-4" />,
                    label: "Category",
                  },
                  {
                    key: "list-category",
                    icon: <MdCategory className="fs-4" />,
                    label: "Category List",
                  },
                  {
                    key: "color",
                    icon: <AiOutlineBgColors className="fs-4" />,
                    label: "Color",
                  },
                  {
                    key: "list-color",
                    icon: <BiColorFill className="fs-4" />,
                    label: "Color List",
                  },
                  {
                    key: "offer",
                    icon: <MdLocalOffer className="fs-4" />,
                    label: "Offer",
                  },
                  {
                    key: "list-offer",
                    icon: <BiSolidOffer className="fs-4" />,
                    label: "Offer List",
                  },
                  {
                    key: "size",
                    icon: <MdOutlinePhotoSizeSelectSmall className="fs-4" />,
                    label: "Size",
                  },
                  {
                    key: "list-size",
                    icon: <RxFontSize className="fs-4" />,
                    label: "Size List",
                  },
                ],
              },
              {
                key: "orders",
                icon: <FaClipboardList className="fs-4" />,
                label: "Orders",
              },
              {
                key: "banner",
                icon: <BiCarousel className="fs-4" />,
                label: "Banners",
                children: [
                  {
                    key: "add-banner",
                    icon: <GiCarousel className="fs-4" />,
                    label: "Add Banner",
                  },
                  {
                    key: "banner-list",
                    icon: <CgCarousel className="fs-4" />,
                    label: "Banner List",
                  },
                ],
              },
              {
                key: "notifications",
                icon: <MdNotificationsActive className="fs-4" />,
                label: "Notifications",
                children: [
                  {
                    key: "notification",
                    icon: <MdNotificationAdd className="fs-4" />,
                    label: "Add Notification",
                  },
                  {
                    key: "notification-list",
                    icon: <MdNotificationsPaused className="fs-4" />,
                    label: "Notification List",
                  },
                ],
              },
              {
                key: "conetent",
                icon: <MdNotificationsActive className="fs-4" />,
                label: "Content",
                children: [
                  {
                    key: "about-us",
                    icon: <MdNotificationAdd className="fs-4" />,
                    label: "Add About Us",
                  },
                  {
                    key: "about-list",
                    icon: <MdNotificationsPaused className="fs-4" />,
                    label: "about List",
                  },
                ],
              },
              {
                key: "enquiries",
                icon: <FaClipboardList className="fs-4" />,
                label: "Enquiries",
              },
              {
                key: "subscriptions",
                icon: <FaClipboardList className="fs-4" />,
                label: "Subscribe Emails",
              },
              {
                key: "signout",
                icon: <AiOutlineLogout className="fs-4" />,
                label: "Sign Out",
              },
            ]}
          />
        </Sider>
        <Layout>
          <Header
            className="d-flex justify-content-between ps-1 px-5"
            style={{
              padding: 0,
              background: colorBgContainer,
            }}
          >
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: "16px",
                width: 64,
                height: 64,
              }}
            />

            <div className="d-flex gap-4 align-items-center">
              {/* <div className="position-relative">
                <IoMdNotifications className="fs-4" />
                <span className="badge bg-primary rounded-circle p-1 position-absolute">
                  3
                </span>
              </div> */}
              <div className="d-flex gap-3 align-items-center">
                <div>
                  <img
                    height={32}
                    width={32}
                    src="https://img.freepik.com/free-vector/isolated-young-handsome-man-different-poses-white-background-illustration_632498-859.jpg?size=338&ext=jpg&ga=GA1.1.1141335507.1717977600&semt=ais_user"
                    alt=""
                  />
                </div>
                <div>
                  <h5 className="mb-0">{authState?.firstname}</h5>
                  <p className="mb-0">{authState?.email}</p>
                </div>

                {/* <DropdownButton id="dropdown-basic-button">
                  <Dropdown.Item
                    className="py-1 mb-2"
                    style={{ height: "auto", lineHeight: "20px" }}
                    href="#/action-1"
                  >
                    View Profile
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="py-1 mb-2"
                    style={{ height: "auto", lineHeight: "20px" }}
                    href="#/action-2"
                  >
                    Signout
                  </Dropdown.Item>
                </DropdownButton> */}
              </div>
            </div>
          </Header>
          <Content
            style={{
              margin: "24px 16px",
              padding: 24,
              minHeight: 280,
              background: colorBgContainer,
            }}
          >
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default MainLayout;
