import axios from "axios";
import { base_url } from "../../utils/base_url";
import { config } from "../../utils/axiosconfig";

const getSizes = async () => {
  const response = await axios.get(`${base_url}/size/get-all-size`);
  return response.data;
};

const createSize = async (size) => {
  const response = await axios.post(
    `${base_url}/size/create-size`,
    size,
    config
  );
  return response.data;
};

const updateSize = async (size) => {
  const response = await axios.put(
    `${base_url}/size/update-size/${size.id}`,
    {
      title: size.sizeData.title,
    },
    config
  );
  return response.data;
};

const getSize = async (id) => {
  const response = await axios.get(
    `${base_url}/size/get-single-size/${id}`,
    config
  );
  return response.data;
};

const deleteSize = async (id) => {
  const response = await axios.delete(
    `${base_url}/size/delete-size/${id}`,
    config
  );
  return response.data;
};

const sizeService = {
  getSizes,
  createSize,
  updateSize,
  getSize,
  deleteSize,
};

export default sizeService;
