import React, { useEffect } from "react";
import CustomInput from "../components/CustomInput";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import * as yup from "yup";
import { useFormik } from "formik";
import { customerSignUp, resetState } from "../features/auth/authSlice";

let Schema = yup.object().shape({
  firstname: yup.string().required("FirstName is Required"),
  lastname: yup.string().required("LastName is Required"),
  email: yup
    .string()
    .email("Email should be valid")
    .required("Email is Required"),
  mobile: yup.number().required("Phone number is Required"),
  role: yup.string().required("Role is Required"),
  password: yup.string().required("Password is Required"),
});

const AddUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      mobile: "",
      role: "",
      password: "",
    },
    validationSchema: Schema,
    onSubmit: (values) => {
      dispatch(customerSignUp(values));
      formik.resetForm();
      setTimeout(() => {
        dispatch(resetState());
      }, 300);
    },
  });

  return (
    <>
      <div>
        <h3 className="mb-4 title">Add User</h3>
        <div>
          <form action="" onSubmit={formik.handleSubmit}>
            <CustomInput
              type="text"
              label="Enter Fist Name"
              name="firstname"
              onCh={formik.handleChange("firstname")}
              onBl={formik.handleBlur("firstname")}
              val={formik.values.firstname}
            />

            <div className="error">
              {formik.touched.firstname && formik.errors.firstname}
            </div>

            <CustomInput
              type="text"
              label="Enter Last Name"
              name="lastname"
              onCh={formik.handleChange("lastname")}
              onBl={formik.handleBlur("lastname")}
              val={formik.values.lastname}
            />

            <div className="error">
              {formik.touched.lastname && formik.errors.lastname}
            </div>

            <CustomInput
              type="email"
              label="Enter Email"
              name="email"
              onCh={formik.handleChange("email")}
              onBl={formik.handleBlur("email")}
              val={formik.values.email}
            />

            <div className="error">
              {formik.touched.email && formik.errors.email}
            </div>

            <CustomInput
              type="number"
              label="Enter Mobole Number"
              name="mobile"
              onCh={formik.handleChange("mobile")}
              onBl={formik.handleBlur("mobile")}
              val={formik.values.mobile}
            />

            <div className="error">
              {formik.touched.mobile && formik.errors.mobile}
            </div>

            <CustomInput
              type="text"
              label="Enter Role"
              name="role"
              onCh={formik.handleChange("role")}
              onBl={formik.handleBlur("role")}
              val={formik.values.role}
            />

            <div className="error">
              {formik.touched.role && formik.errors.role}
            </div>

            <CustomInput
              type="password"
              label="Enter Password"
              name="password"
              onCh={formik.handleChange("password")}
              onBl={formik.handleBlur("password")}
              val={formik.values.password}
            />

            <div className="error">
              {formik.touched.password && formik.errors.password}
            </div>

            <button
              type="submit"
              className="btn btn-success border-0 rounded-3 my-5"
            >
              Add Users
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddUser;
