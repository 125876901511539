import axios from "axios";
import { base_url } from "../../utils/base_url";
import { config } from "../../utils/axiosconfig";

export const createAbout = async (data) => {
  try {
    const response = await axios.post(
      `${base_url}/about/create-about`,
      data,
      config
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getAabout = async (id) => {
  try {
    const response = await axios.get(
      `${base_url}/about/get-single-about/${id}`
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getAbout = async () => {
  try {
    const response = await axios.get(`${base_url}/about/get-all-about`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteAbout = async (id) => {
  try {
    const response = await axios.delete(
      `${base_url}/about/delete-about/${id}`,
      config
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const udpateAbout = async (about) => {
  try {
    const response = await axios.put(
      `${base_url}/about/update-about/${about.id}`,
      {
        heading: about.aboutData.heading,
        content: about.aboutData.content,
      },
      config
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const aboutService = {
  createAbout,
  getAabout,
  getAbout,
  deleteAbout,
  udpateAbout,
};

export default aboutService;
